import { action } from "typesafe-actions";
import { HomeActionTypes } from "./Model";

export const askQusRequest = (props) =>
  action(HomeActionTypes.ASKQUESTION_REQUEST, props);

export const askQusSuccess = (props) =>
  action(HomeActionTypes.ASKQUESTION_SUCCESS, props);

export const askQusFailure = (props) =>
  action(HomeActionTypes.ASKQUESTION_FAILURE, props);

export const getchatHistoryRequest = (props) =>
  action(HomeActionTypes.GET_CHAT_HISTORY_REQUEST, props);

export const getchatHistorySuccess = (props) =>
  action(HomeActionTypes.GET_CHAT_HISTORY_SUCCESS, props);

export const getchatHistoryFailure = (props) =>
  action(HomeActionTypes.GET_CHAT_HISTORY_FAILURE, props);
