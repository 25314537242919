import { call, put, takeEvery } from "redux-saga/effects";
import { LawyerActionItems } from "./Model";
import {
  getCaseDetailFailure,
  getCaseDetailSuccess,
  getLawyerProfileFailure,
  getLawyerProfileSuccess,
  lawyerloginFailure,
  lawyerloginSuccess,
  lawyersignupFailure,
  lawyersignupSuccess,
  postCaseDetailFailure,
  postCaseDetailSuccess,
  postSettingDetailFailure,
  postSettingDetailSuccess,

} from "./Action";
import { login, signUp, postCaseDetail, getCaseDetail, getLawyerUserDetail, postsettingDetail } from "./api";

export function* handleSignup(action) {
  try {
    const response = yield call(signUp, action.payload);
    yield put(lawyersignupSuccess(response));
  } catch (error) {
    yield put(lawyersignupFailure(error));
  }
}

export function* handleLogin(action) {
  try {
    const response = yield call(login, action.payload);
    yield put(lawyerloginSuccess(response));
  } catch (error) {
    yield put(lawyerloginFailure(error));
  }
}

/* Saga For Post& Get Case Detail */
export function* handlePostCaseDetail(action) {
  try {
    const response = yield call(postCaseDetail, action.payload);
    yield put(postCaseDetailSuccess(response));
  } catch (error) {
    yield put(postCaseDetailFailure(error));
  }
}

export function* handleGetCaseDetail(action) {
  try {
    const response = yield call(getCaseDetail, action.payload);
    yield put(getCaseDetailSuccess(response));
  } catch (error) {
    yield put(getCaseDetailFailure(error));
  }
}

/* Saga For Post& Get Profile Detail */
export function* handlePostUpdatedProfile(action) {

  try {
console.log(action.payload, "tyvbuhnjim")
    const response = yield call(postsettingDetail, action.payload);
    yield put(postSettingDetailSuccess(response));
  } catch (error) {
    yield put(postSettingDetailFailure(error));
  }
}

export function* handleGetUpdatedProfile(action) {
  try {
    const response = yield call(getLawyerUserDetail, action.payload);
    yield put(getLawyerProfileSuccess(response));
  } catch (error) {
    yield put(getLawyerProfileFailure(error));
  }
}
export function* LawyerSaga() {
  yield takeEvery(LawyerActionItems.SIGNUP_REQUEST, handleSignup);
  yield takeEvery(LawyerActionItems.LOGIN_REQUEST, handleLogin);
  yield takeEvery(LawyerActionItems.POSTCASEDETAIL_REQUEST,handlePostCaseDetail);
  yield takeEvery(LawyerActionItems.GETCASEDETAIL_REQUEST, handleGetCaseDetail);
  yield takeEvery(LawyerActionItems.POSTSETTINGUPDATEDETAIL_REQUEST,handlePostUpdatedProfile);
  yield takeEvery(LawyerActionItems.GETLAWYERPROFILEDETAIL_REQUEST,handleGetUpdatedProfile);

}
