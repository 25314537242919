import { action } from "typesafe-actions";
import { LawyerActionItems } from "./Model";

export const lawyerloginRequest = (props) =>
  action(LawyerActionItems.LOGIN_REQUEST, props);

export const lawyerloginSuccess = (props) =>
  action(LawyerActionItems.LOGIN_SUCCESS, props);

export const lawyerloginFailure = (props) =>
  action(LawyerActionItems.LOGIN_FAILURE, props);

export const lawyersignupRequest = (props) =>
  action(LawyerActionItems.SIGNUP_REQUEST, props);

export const lawyersignupSuccess = (props) =>
  action(LawyerActionItems.SIGNUP_SUCCESS, props);

export const lawyersignupFailure = (props) =>
  action(LawyerActionItems.SIGNUP_FAILURE, props);

/* Post & Get CaseDetail Action */

export const postCaseDetailRequest = (props) =>
  action(LawyerActionItems.POSTCASEDETAIL_REQUEST, props);

export const postCaseDetailSuccess = (props) =>
  action(LawyerActionItems.POSTCASEDETAIL_SUCCESS, props);

export const postCaseDetailFailure = (props) =>
  action(LawyerActionItems.POSTCASEDETAIL_FAILURE, props);

export const getCaseDetailRequest = (props) =>
  action(LawyerActionItems.GETCASEDETAIL_REQUEST, props);

export const getCaseDetailSuccess = (props) =>
  action(LawyerActionItems.GETCASEDETAIL_SUCCESS, props);

export const getCaseDetailFailure = (props) =>
  action(LawyerActionItems.GETCASEDETAIL_FAILURE, props);

/* Update Setting Detail Post & Get  Action*/

export const postSettingDetailRequest = (props) =>
  action(LawyerActionItems.POSTSETTINGUPDATEDETAIL_REQUEST, props);

export const postSettingDetailSuccess = (props) =>
  action(LawyerActionItems.POSTSETTINGUPDATEDETAIL_SUCCESS, props);

export const postSettingDetailFailure = (props) =>
  action(LawyerActionItems.POSTSETTINGUPDATEDETAIL_FAILURE, props);

export const getLawyerProfileRequest = (props) =>
  action(LawyerActionItems.GETLAWYERPROFILEDETAIL_REQUEST, props);

export const getLawyerProfileSuccess = (props) =>
  action(LawyerActionItems.GETLAWYERPROFILEDETAIL_SUCCESS, props);

export const getLawyerProfileFailure = (props) =>
  action(LawyerActionItems.GETLAWYERPROFILEDETAIL_FAILURE, props);

export const clearLawyerMessage = (props) =>
  action(LawyerActionItems.CLEAR_LAWYERMESSAGE, props);
