import { Button, Card, CardBody, Col, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Alert, Grid, IconButton, TextField } from "@mui/material";
import Logo from "../../assets/image/logo.png";
import { userSignUp } from "./Validator.js";
import { useDispatch, useSelector } from "react-redux";
import { clearFlag, signupRequest } from "./Action.js";
import { Link } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import { userSelector } from "./Model.js";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useEffect } from "react";

const Signup = () => {
  const dispatch = useDispatch();
  const { isSignedUp, errorMsg } = useSelector(userSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(userSignUp),
  });

  console.log(errors);

  const onSubmitHandler = (formData) => {
    console.log(formData);
    delete formData.confirmpasswrd;
    formData.mobile_no = parseInt(formData.mobile_no);
    dispatch(signupRequest(formData));
  };

  const handleClose = () => {
    dispatch(clearFlag());
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <IoCloseCircleOutline fontSize="small" className="fs-4" />
      </IconButton>
    </>
  );

  useEffect(() => {
    if (isSignedUp) {
      reset();
    }
  }, [isSignedUp]);

  return (
    <>
      {/* <div className="h-100 w-100 d-flex justify-content-center align-items-center">
        <Card className="rounded-3 p-5 shadow">
          <CardBody>
            <div>
              <div className="text-center">
                <img src={Logo} alt="" className="d-block mb-4" />
                <b>Signup</b>
              </div>
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div>
                  <Row className="mt-3 p-3">
                    <Col md={6}>
                      <div className="input-container">
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Name"
                          variant="standard"
                          {...register("name")}
                        />
                        <div>
                          {errors?.name && (
                            <p className="text-danger mt-1">
                              {errors?.name?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="input-container">
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Email"
                          variant="standard"
                          {...register("email")}
                        />
                        <div>
                          {errors?.email && (
                            <p className="text-danger mt-1">
                              {errors?.email?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-1 p-3">
                    <Col md={6}>
                      <div className="input-container">
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Phone Number"
                          variant="standard"
                          {...register("mobile_no")}
                        />
                        {errors?.mobile_no && (
                          <p className="text-danger mt-1">
                            {errors?.mobile_no?.message}
                          </p>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className="input-container">
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Password"
                          variant="standard"
                          type="password"
                          {...register("password")}
                        />
                        {errors?.password && (
                          <p className="text-danger mt-1">
                            {errors?.password?.message}
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-1 p-3 ">
                    <Col md={6}>
                      <div className="input-container">
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Confirm Password"
                          variant="standard"
                          type="password"
                          {...register("confirmpasswrd")}
                        />
                        {errors?.confirmpasswrd && (
                          <p className="text-danger mt-1">
                            {errors?.confirmpasswrd?.message}
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="text-center mt-1">
                  <Button type="submit">Signup</Button>
                </div>
                <Grid container className="text-center">
                  <Grid item sm>
                    <Link to="/signin" variant="body2">
                      {"Already have an account?   SignIn"}
                    </Link>
                  </Grid>
                </Grid>
              </form>
            </div>
          </CardBody>
        </Card>
      </div> */}

      <div className="h-100 w-100 d-flex justify-content-center align-items-center">
        <Snackbar
          open={isSignedUp}
          autoHideDuration={5000}
          onClose={handleClose}
          message={"User Registered Successfully"}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            User Registered Successfully
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMsg?.error}
          autoHideDuration={5000}
          onClose={handleClose}
          message={errorMsg?.message}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errorMsg?.message}
          </Alert>
        </Snackbar>
        <Card className="rounded-3 p-5 shadow">
          <CardBody>
          <form onSubmit={handleSubmit(onSubmitHandler)}>

            <div>
              <div className="logo-width text-center">
                <img src={Logo} alt="" className="d-block mb-4" />
                <h3 className="login">Signup</h3>
              </div>
              <div className="form-space">
                <TextField
                  className="w-100"
                  id="standard-basic"
                  label="Name"
                  variant="outlined"
                  {...register("name")}
                />  {errors?.name && (
                            <p className="text-danger mt-1">
                              {errors?.name?.message}
                            </p>
                          )}
                      
              </div>
              <div className="form-space">
                <TextField
                  className="w-100"
                  id="standard-basic"
                  label="Email"
                  variant="outlined"
                  {...register("email")}
                />
                 {errors?.email && (
                            <p className="text-danger mt-1">
                              {errors?.email?.message}
                            </p>
                          )}
              </div>
              <div className="form-space">
                <TextField
                  className="w-100"
                  id="standard-basic"
                  label="Phone Number"
                  variant="outlined"
                  {...register("mobile_no")}
                />
                {errors?.mobile_no && (
                          <p className="text-danger mt-1">
                            {errors?.mobile_no?.message}
                          </p>
                        )}
               
              </div>
              <div className="form-space">
                <TextField
                  className="w-100"
                  id="standard-basic"
                  label="Password"
                  variant="outlined"
                  type="password"
                  {...register("password")}
                />
                 {errors?.password && (
                          <p className="text-danger mt-1">
                            {errors?.password?.message}
                          </p>
                        )}
              </div>
              <div>
                <TextField
                  className="w-100"
                  id="standard-basic"
                  label="Confirm Password"
                  variant="outlined"
                  type="password"
                  {...register("confirmpasswrd")}
                />
                     {errors?.confirmpasswrd && (
                          <p className="text-danger mt-1">
                            {errors?.confirmpasswrd?.message}
                          </p>
                        )}
              </div>

              <div className="text-center mt-1">
                <Button className="submit-btn" type="submit">
                  Signup
                </Button>
              </div>

              <div className="text">
                <p>Already have an account? 
                  <span><Link to="/signin" >Signin</Link></span>
                  </p>
              </div>
            </div>
            </form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Signup;
