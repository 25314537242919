import { Button, Card, CardBody, Col, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  Alert,
  Grid,
  IconButton,
  Rating,
  Snackbar,
  TextField,
} from "@mui/material";
import Logo from "../../assets/image/logo.png";
import { lawyerSignUpValidator, paymentValidator } from "./Validator";
import { useDispatch, useSelector } from "react-redux";
import { clearLawyerMessage, lawyersignupRequest } from "./Action";

import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
// import Button from '@mui/material/Button';
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { Link } from "react-router-dom";
import { lawyerSelector } from "./Selector";
import { IoCloseCircleOutline } from "react-icons/io5";

const steps = ["Profile Details", "Professional Details", "Skill Analysis"];

const LawyerSignup = () => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(lawyerSignUpValidator),
  });

  console.log(errors, "edfgfggfdg");

  const { lawyerMessage, errorMsg } = useSelector(lawyerSelector);

  const onSubmitHandler = (formData) => {
    console.log(formData, "ojii");
    formData.mobile_no = parseInt(formData.mobile_no);
    dispatch(lawyersignupRequest(formData));
  };

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = (e) => {
    e.preventDefault();
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const handleClose = () => {
    dispatch(clearLawyerMessage());
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <IoCloseCircleOutline fontSize="small" className="fs-4" />
      </IconButton>
    </>
  );
  return (
    <>
      {/* <div className="h-100 w-100 d-flex justify-content-center align-items-center">
       <Snackbar
          open={lawyerMessage=="Lawyer Sign up successfully!"}
          autoHideDuration={5000}
          onClose={handleClose}
          message={lawyerMessage}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {lawyerMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMsg?.error}
          autoHideDuration={1000}
          onClose={handleClose}
          message={errorMsg?.message}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errorMsg?.message}
          </Alert>
        </Snackbar>
    <form onSubmit={handleSubmit(onSubmitHandler)}>

        <Card className="rounded-3 p-5 shadow">
          <CardBody style={{ width: "50vw" }}>
            <div>
              <div className="text-center">
                <img src={Logo} alt="Logo" />
                <h3 className=" d-block login border-0 mt-4">Laywer Signup</h3>
              </div>
              <div>
              <div>
                              <Row className="mt-3 p-3">
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Name"
                                      variant="standard"
                                      {...register("name")}
                                    />
                                    <div>
                                      {errors?.name && (
                                        <p className="text-danger mt-1">
                                          {errors?.name?.message}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Email"
                                      variant="standard"
                                      {...register("email")}
                                    />
                                    <div>
                                      {errors?.email && (
                                        <p className="text-danger mt-1">
                                          {errors?.email?.message}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-1 p-3">
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Phone Number"
                                      variant="standard"
                                      {...register("phone_no")}
                                    />
                                    {errors?.phone_no && (
                                      <p className="text-danger mt-1">
                                        {errors?.phone_no?.message}
                                      </p>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="District"
                                      variant="standard"
                                      {...register("district")}
                                    />
                                    {errors?.district && (
                                      <p className="text-danger mt-1">
                                        {errors?.district?.message}
                                      </p>
                                    )}
                                  </div>
                                </Col>
                               
                              </Row>
                              <Row className="mt-1 p-3">
                              <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="State"
                                      variant="standard"
                                      {...register("state")}
                                    />
                                    {errors?.state && (
                                      <p className="text-danger mt-1">
                                        {errors?.state?.message}
                                      </p>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Bar Regn. Number"
                                      name="bar_no"
                                      variant="standard"
                                      {...register("bar_no")}
                                    />
                                    <div>
                                      {errors?.bar_no && (
                                        <p className="text-danger mt-1">
                                          {errors?.bar_no?.message}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-3 p-3">
                                
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Enroll Number"
                                      variant="standard"
                                      {...register("enroll_no")}
                                    />
                                    <div>
                                      {errors?.enroll_no && (
                                        <p className="text-danger mt-1">
                                          {errors?.enroll_no?.message}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Password"
                                      variant="standard"
                                      type="password"
                                      {...register("password")}
                                    />
                                    {errors?.password && (
                                      <p className="text-danger mt-1">
                                        {errors?.password?.message}
                                      </p>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-1 p-3">
                                
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Confirm Password"
                                      variant="standard"
                                      type="password"
                                      {...register("confirmpasswrd")}
                                    />
                                    {errors?.confirmpasswrd && (
                                      <p className="text-danger mt-1">
                                        {errors?.confirmpasswrd?.message}
                                      </p>
                                    )}
                                  </div>
                                </Col>
                                <input type="submit" placeholder="gfg"/>
                              </Row>
                              
                            </div >
                            
                            <div className="" style={{textAlign:"end"}}>
                            <Button type="submit" className="">
                            Register
                          </Button>
                          <Grid container className="text-center">
                  <Grid item sm>
                    <Link to="/lawyer/signin" variant="body2">
                      {"Already have an account?   SignIn"}
                    </Link>
                  </Grid>
                </Grid>
                            </div>
                            
              </div>

                  <div className="" style={{ textAlign: "end" }}>
                    <Button type="submit" className="">
                      Register
                    </Button>
                    <Grid container className="text-center">
                      <Grid item sm>
                        <Link to="/lawyer/signin" variant="body2">
                          {"Already have an account?   SignIn"}
                        </Link>
                      </Grid>
                    </Grid>
                  </div>
                </div>

              <Box sx={{ width: "100%", marginTop: "10px" }}>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                  <Stepper activeStep={activeStep}>
                    {steps.map((label, index) => {
                      const stepProps = {};
                      const labelProps = {};
                      return (
                        <Step key={label} {...stepProps}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                      );
                    })}
                  </Stepper>
                  {activeStep === steps.length ? (
                    <>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        All steps completed - you&apos;re finished
                      </Typography>
                      <Box
                        sx={{ display: "flex", flexDirection: "row", pt: 2 }}
                      >
                        <Box sx={{ flex: "1 1 auto" }} />
                        <Button onClick={handleReset}>Reset</Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography sx={{ mt: 2, mb: 1 }}>
                        {activeStep == 0 ? (
                          <>
                            <div>
                              <Row className="mt-3 p-3">
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Name"
                                      variant="standard"
                                      {...register("name")}
                                    />
                                    <div>
                                      {errors?.name && (
                                        <p className="text-danger mt-1">
                                          {errors?.name?.message}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Email"
                                      variant="standard"
                                      {...register("email")}
                                    />
                                    <div>
                                      {errors?.email && (
                                        <p className="text-danger mt-1">
                                          {errors?.email?.message}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-1 p-3">
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Phone Number"
                                      variant="standard"
                                      {...register("mobile_no")}
                                    />
                                    {errors?.mobile_no && (
                                      <p className="text-danger mt-1">
                                        {errors?.mobile_no?.message}
                                      </p>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="District"
                                      variant="standard"
                                      {...register("district")}
                                    />
                                    {errors?.district && (
                                      <p className="text-danger mt-1">
                                        {errors?.district?.message}
                                      </p>
                                    )}
                                  </div>
                                </Col>
                               
                              </Row>
                              <Row className="mt-1 p-3">
                              <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="State"
                                      variant="standard"
                                      {...register("state")}
                                    />
                                    {errors?.state && (
                                      <p className="text-danger mt-1">
                                        {errors?.state?.message}
                                      </p>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : activeStep == 1 ? (
                          <>
                            <div>
                              <Row className="mt-3 p-3">
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Bar Regn. Number"
                                      name="bar_no"
                                      variant="standard"
                                      {...register("bar_no")}
                                    />
                                    <div>
                                      {errors?.bar_no && (
                                        <p className="text-danger mt-1">
                                          {errors?.bar_no?.message}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Enroll Number"
                                      variant="standard"
                                      {...register("enroll_no")}
                                    />
                                    <div>
                                      {errors?.enroll_no && (
                                        <p className="text-danger mt-1">
                                          {errors?.enroll_no?.message}
                                        </p>
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-1 p-3">
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="No.of cases"
                                      variant="standard"
                                      {...register("no_of_cases")}
                                    />
                                    {errors?.no_of_cases && (
                                      <p className="text-danger mt-1">
                                        {errors?.no_of_cases?.message}
                                      </p>
                                    )}
                                  </div>
                                </Col>
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="Won count"
                                      variant="standard"
                                      {...register("won_cases")}
                                    />
                                    {errors?.won_cases && (
                                      <p className="text-danger mt-1">
                                        {errors?.won_cases?.message}
                                      </p>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                              <Row className="mt-1 p-3">
                                <Col md={6}>
                                  <div className="input-container">
                                    <TextField
                                      className="w-100"
                                      id="standard-basic"
                                      label="District"
                                      variant="standard"
                                      {...register("district")}
                                    />
                                    {errors?.district && (
                                      <p className="text-danger mt-1">
                                        {errors?.district?.message}
                                      </p>
                                    )}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="p-2">
                              <Row className="mt-3 p-1">
                                <Col md={6}>
                                  <h5>Analytic and Research Skills</h5>
                                  <Rating className="fs-2" precision={0.5} />
                                </Col>
                                <Col md={6}>
                                  <h5>Organizational Skills</h5>
                                  <Rating className="fs-2" precision={0.5} />
                                </Col>
                              </Row>
                              <Row className="mt-1 p-1">
                                <Col md={6}>
                                  <h5>Time Management Skills</h5>
                                  <Rating className="fs-2" precision={0.5} />
                                </Col>
                                <Col md={6}>
                                  <h5>Persuasive Communication Skills</h5>
                                  <Rating className="fs-2" precision={0.5} />
                                </Col>
                              </Row>
                              <Row className="mt-1 p-1">
                                <Col md={6}>
                                  <h5>Technical Skills</h5>
                                  <Rating className="fs-2" precision={0.5} />
                                </Col>
                                <Col md={6}>
                                  <h5>Interpersonal Skills</h5>
                                  <Rating className="fs-2" precision={0.5} />
                                </Col>
                              </Row>
                            </div>
                          </>
                        )}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          pt: 2,
                        }}
                      >
                        <Button
                          color="inherit"
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          sx={{ mr: 1 }}
                        >
                          Back
                        </Button>
                        <Box sx={{ flex: "1 1 auto" }} />
                        {activeStep == 2 ? (
                          <Button type="submit">Finish</Button>
                        ) : (
                          <Button type="button" onClick={handleNext}>
                            Next
                          </Button>
                        )}
                      </Box>
                    </>
                  )}
                </form>
              </Box>
            </div>
          </CardBody>
        </Card>
      </form>

      </div> */}

      <div className="h-100 w-100 d-flex justify-content-center align-items-center">
        <Snackbar
          open={lawyerMessage == "Lawyer Sign up successfully!"}
          autoHideDuration={5000}
          onClose={handleClose}
          message={lawyerMessage}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {lawyerMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMsg?.error}
          autoHideDuration={1000}
          onClose={handleClose}
          message={errorMsg?.message}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errorMsg?.message}
          </Alert>
        </Snackbar>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Card className="rounded-3 p-5 shadow">
            <CardBody>
              <div>
                <div className="text-center image-center">
                  <img src={Logo} alt="" className="d-block mb-4" />
                  <h3 className="login">Lawyer Signup</h3>
                </div>
                <Row>
                  <Col md={6}>
                    <div className="form-space">
                      <TextField
                        className="w-100"
                        id="standard-basic"
                        label="Name"
                        variant="outlined"
                        {...register("name")}
                      />
                    </div>
                    <div>
                      {errors?.name && (
                        <p className="text-danger mt-1">
                          {errors?.name?.message}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-space">
                      <TextField
                        className="w-100"
                        id="standard-basic"
                        label="Email"
                        variant="outlined"
                        {...register("email")}
                      />
                    </div>
                    <div>
                      {errors?.email && (
                        <p className="text-danger mt-1">
                          {errors?.email?.message}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="form-space">
                      <TextField
                        className="w-100"
                        id="standard-basic"
                        label="Phone Number"
                        variant="outlined"
                        {...register("mobile_no")}
                      />
                    </div>
                    <div>
                      {errors?.mobile_no && (
                        <p className="text-danger mt-1">
                          {errors?.mobile_no?.message}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-space">
                      <TextField
                        className="w-100"
                        id="standard-basic"
                        label="Enroll Number"
                        variant="outlined"
                        {...register("enroll_no")}
                      />
                    </div>
                    <div>
                      {errors?.enroll_no && (
                        <p className="text-danger mt-1">
                          {errors?.enroll_no?.message}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="form-space">
                      <TextField
                        className="w-100"
                        id="standard-basic"
                        label="District"
                        variant="outlined"
                        {...register("district")}
                      />
                    </div>
                    <div>
                      {errors?.district && (
                        <p className="text-danger mt-1">
                          {errors?.district?.message}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-space">
                      <TextField
                        className="w-100"
                        id="standard-basic"
                        label="State"
                        variant="outlined"
                        {...register("state")}
                      />
                    </div>
                    <div>
                      {errors?.state && (
                        <p className="text-danger mt-1">
                          {errors?.state?.message}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={6}>
                    <div className="form-space">
                      <TextField
                        className="w-100"
                        id="standard-basic"
                        label="Password"
                        name="bar_no"
                        variant="outlined"
                        type="password"
                        {...register("password")}
                      />
                    </div>
                    <div>
                      {errors?.password && (
                        <p className="text-danger mt-1">
                          {errors?.password?.message}
                        </p>
                      )}
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-space">
                      <TextField
                        className="w-100"
                        id="standard-basic"
                        label="Confirm Password"
                        variant="outlined"
                        type="password"
                        {...register("confirmpasswrd")}
                      />
                    </div>
                    <div>
                      {errors?.confirmpasswrd && (
                        <p className="text-danger mt-1">
                          {errors?.confirmpasswrd?.message}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div>
                      <TextField
                        className="w-100"
                        id="standard-basic"
                        label="Bar Regn. Number"
                        variant="outlined"
                        {...register("bar_no")}
                      />
                    </div>
                    <div>
                      {errors?.bar_no && (
                        <p className="text-danger mt-1">
                          {errors?.bar_no?.message}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>

                <div className="text-center mt-1">
                  <Button className="submit-btn" type="submit">
                    Signup
                  </Button>
                </div>

                <div className="text">
                  <p>
                    Already have an account?{" "}
                    <span>
                      <Link to={"/lawyer/signin"}>Signin</Link>
                    </span>
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        </form>
      </div>
    </>
  );
};

export default LawyerSignup;
