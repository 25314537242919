import { Alert, IconButton, Snackbar, Tab, TextField } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Form,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { profileValidator } from "./Validator";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { faCamera } from "@fortawesome/free-solid-svg-icons";
import profile from "../../assets/image/profile.jpg";
import { IoCloseCircleOutline } from "react-icons/io5";
import { messages } from "../../utils/messages";
import { userSelector } from "./Model";
import {
  UpdateUserProfileRequest,
  clearFlag,
  getUserProfileRequest,
} from "./Action";
const LawyerSettings = () => {
  const dispatch = useDispatch();
  const id = localStorage.getItem("user_id");

  const [value, setValue] = useState("1");
  const { userData, userMessage, errorMsg } = useSelector(userSelector);
  console.log(userData, userMessage, "userData");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(profileValidator),
  });

  console.log(errors, "errors");

  const handleClose = () => {
    dispatch(clearFlag());
  };

  useEffect(() => {
    if (userMessage == messages.get_userDetails) reset(userData);
    if (userMessage == messages.userDetails_updated)
      dispatch(getUserProfileRequest(id));
    dispatch(clearFlag());
  }, [userData, userMessage]);

  useEffect(() => {
    dispatch(getUserProfileRequest(id));
  }, [id]);

  const onSubmitHandler = (formData) => {
    delete formData.updated;
    delete formData.password;
    delete formData.created;
    dispatch(UpdateUserProfileRequest({ formData }));
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <IoCloseCircleOutline fontSize="small" className="fs-4" />
      </IconButton>
    </>
  );

  return (
    <div>
      <section>
        <Container>
          <Snackbar
            open={userMessage == messages.userDetails_updated}
            autoHideDuration={5000}
            onClose={handleClose}
            // message={userMessage}
            action={action}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {userMessage}
            </Alert>
          </Snackbar>
          <Snackbar
            open={errorMsg?.error}
            autoHideDuration={5000}
            onClose={handleClose}
            message={errorMsg?.message}
            action={action}
          >
            <Alert
              onClose={handleClose}
              severity="error"
              variant="filled"
              sx={{ width: "100%" }}
            >
              {errorMsg?.message}
            </Alert>
          </Snackbar>
          <Row className="profile">
            <Col md={4}>
              <div className="card-body-style image">
                <Col className="profile-col" md={4}>
                  <div className="profile-bg">
                    <FontAwesomeIcon className="circle" icon={faCamera} />
                  </div>
                </Col>
              </div>
            </Col>
            <Col md={8}>
              <div className="card-body-style">
                <h3 className="profile-title">Profile</h3>
                <Form
                  onSubmit={handleSubmit(onSubmitHandler)}
                  className="profile-form"
                >
                  <Row>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="w-100 m-1"
                          variant="outlined"
                          label={"Name"}
                          {...register("name")}
                        />
                        {errors?.name && (
                          <div>
                            <p className="text-danger">
                              {errors?.name?.message}
                            </p>
                          </div>
                        )}
                      </div>
                    </Col>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="color w-100 m-1"
                          variant="outlined"
                          label={"Email"}
                          disabled
                          {...register("email")}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <div>
                        <TextField
                          className="color w-100 m-1"
                          variant="outlined"
                          label={"Phone Number"}
                          disabled
                          {...register("mobile_no")}
                        />
                      </div>
                    </Col>
                  </Row>

                  <div className="text-end">
                    <Button className="update" type="submit">
                      Update
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default LawyerSettings;
