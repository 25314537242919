import { ListItem, ListItemButton } from '@mui/material';
import React from 'react'
import { Card } from 'react-bootstrap';

function Enquiry() {

  const probData = [
    { id: 0, value: 40, label: "Solved", person: "Vibin" },
    { id: 1, value: 10, label: "Un Solved", person: "Kishor" },
  ];

  return (
    <div>
      <div className='card-body-style responsive-margin hr-style'>
        <h5>Enquiries</h5>
        <hr/>
        {probData?.map((data, i) => {
          return (
            <>
              <ListItem component="div" disablePadding>
                <ListItemButton>
                  <h6>{data?.person}</h6>
                </ListItemButton>
              </ListItem>
            </>
          );
        })}

      </div>
    </div>
  )
}

export default Enquiry
