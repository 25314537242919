import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FaFontAwesome, FaRobot } from "react-icons/fa";
import { faGavel, faRobot } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { Skeleton } from "@mui/material";

const Chat = ({ message, speed = 10, question, loading, isStop }) => {
  const [displayedText, setDisplayedText] = useState("");
  console.log(message, "9y38y9347");

  // useEffect(() => {
  //   let index = 0;
  //   const intervalId = setInterval(() => {
  //     if (message && message[index] != "") {
  //       console.log(loading, "message");
  //       setDisplayedText((prev) => prev + message[index]);
  //     }
  //     index++;
  //     if (index === message?.length || isStop) {
  //       clearInterval(intervalId);
  //       loading(false);
  //     }
  //   }, speed);

  //   return () => clearInterval(intervalId);
  // }, [message, speed, isStop]);

  return (
    <>
      <section className="bd">
        <div className="scroll">
          <div className="ai-chat">
            <div>
              <FontAwesomeIcon className="ai-icon" icon={faRobot} />
            </div>
            <div className="ai-text-info">
              <p>Hello! How Can I help you?</p>
            </div>
          </div>
          {isStop && (
            <>
              <div className="user-chat">
                <div className="user-text-info">
                  <Skeleton
                    variant="text"
                    animation={"wave"}
                    sx={{ fontSize: "1rem", width: "50px" }}
                  />
                </div>
                <div>
                  <Skeleton
                    variant="circular"
                    animation={"wave"}
                    sx={{ fontSize: "1rem", width: "20px" }}
                  />
                </div>
              </div>
              <div className="ai-chat">
                <div>
                  <Skeleton
                    variant="circular"
                    animation={"wave"}
                    sx={{ fontSize: "1rem", width: "20px" }}
                  />
                </div>
                <div className="ai-text-info">
                  <p>
                    <b>
                      {" "}
                      <Skeleton
                        variant="text"
                        animation={"wave"}
                        sx={{ fontSize: "1rem", width: "25px" }}
                      />
                    </b>{" "}
                    <Skeleton
                      variant="text"
                      animation={"wave"}
                      sx={{ fontSize: "1rem", width: "50px" }}
                    />
                  </p>
                  <p>
                    <b>
                      {" "}
                      <Skeleton
                        variant="text"
                        animation={"wave"}
                        sx={{ fontSize: "1rem", width: "25px" }}
                      />
                    </b>{" "}
                    <Skeleton
                      variant="text"
                      animation={"wave"}
                      sx={{ fontSize: "1rem", width: "50px" }}
                    />
                  </p>
                </div>
              </div>
            </>
          )}

          {message?.map((data,index) => {
            return (
              <>
                <div className="user-chat" key={index}>
                  <div className="user-text-info">
                    <p>{data?.emitData}</p>
                  </div>
                  <div>
                    <FontAwesomeIcon className="user-icon" icon={faUser} />
                  </div>
                </div>
                <div className="ai-chat">
                  <div>
                    <FontAwesomeIcon className="ai-icon" icon={faRobot} />
                  </div>
                  <div className="ai-text-info">
                    <p>
                      <b>Description :</b> {data?.description}
                    </p>
                    <p>
                      <b>Punishment :</b> {data?.punishment}
                    </p>
                  </div>
                </div>
              </>
            );
          })}

          {/* <div className="ai-chat">
                <div>
                  <FontAwesomeIcon className="ai-icon" icon={faRobot} />
                </div>
                <div className="ai-text-info">
                  <p>
                    Welcome to our website, your one-stop solution for effective
                    site structuring. We specialize in architecture design,
                    experience optimization, SEO strategy, and responsive
                    design. Our team is dedicated to creating user-friendly,
                    efficient, and visually appealing websites that enhance user
                    experience and drive engagement. Whether you're starting
                    from scratch or looking to revamp your existing site, we
                    have the expertise to meet your needs. Discover the
                    difference a well-structured website can make. Contact us
                    today to transform your online presence. Let's build
                    something great together!
                  </p>
                </div>
              </div>

              <div className="user-chat">
                <div className="user-text-info">
                  <p>Thanks for this information</p>
                </div>
                <div>
                  <FontAwesomeIcon className="user-icon" icon={faUser} />
                </div>
              </div> */}

          {/* <div className="ai-chat">
                <div>
                  <FontAwesomeIcon className="ai-icon" icon={faRobot} />
                </div>
                <div className="ai-text-info">
                  <p>
                    Welcome to our website, your one-stop solution for effective
                    site structuring. We specialize in architecture design,
                    experience optimization, SEO strategy, and responsive
                    design. Our team is dedicated to creating user-friendly,
                    efficient, and visually appealing websites that enhance user
                    experience and drive engagement. Whether you're starting
                    from scratch or looking to revamp your existing site, we
                    have the expertise to meet your needs. Discover the
                    difference a well-structured website can make. Contact us
                    today to transform your online presence. Let's build
                    something great together!
                  </p>
                </div>
              </div> */}
        </div>
      </section>
      {/* </Col>
        <Col className="bg-width" md={3}>
          {/* <div className="bg">
              <div className="history">
                <ul>
                  <div className="history-content">
                    <span>Today</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>Yesterday</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>

                  <div className="history-content">
                    <span>June 10</span>
                    <hr/>
                    <li><FontAwesomeIcon className="law" icon={faGavel}/>How does the concept of "beyond a reasonable doubt"
                          apply in criminal cases?
                    </li>
                  </div>
                </ul>
              </div>
            </div> */}
      {/* </Col>
      </Row> */}
    </>
  );
};

export default Chat;
