import { action } from "typesafe-actions";
import { UserActionItems } from "./Model";

export const loginRequest = (props) =>
  action(UserActionItems.LOGIN_REQUEST, props);

export const loginSuccess = (props) =>
  action(UserActionItems.LOGIN_SUCCESS, props);

export const loginFailure = (props) =>
  action(UserActionItems.LOGIN_FAILURE, props);

export const signupRequest = (props) =>
  action(UserActionItems.SIGNUP_REQUEST, props);

export const signupSuccess = (props) =>
  action(UserActionItems.SIGNUP_SUCCESS, props);

export const signupFailure = (props) =>
  action(UserActionItems.SIGNUP_FAILURE, props);

export const logoutRequest = (props) => action(UserActionItems.LOGOUT, props);

/* User Profile Get Api */

export const getUserProfileRequest = (props) =>
  action(UserActionItems.USERPROFILE_REQUEST, props);

export const getUserProfileSuccess = (props) =>
  action(UserActionItems.USERPROFILE_SUCCESS, props);

export const getUserProfileFailure = (props) =>
  action(UserActionItems.USERPROFILE_FAILURE, props);

// update user api

export const UpdateUserProfileRequest = (props) =>
  action(UserActionItems.UPDATE_USERPROFILE_REQUEST, props);

export const UpdateUserProfileSuccess = (props) =>
  action(UserActionItems.UPDATE_USERPROFILE_SUCCESS, props);

export const UpdateUserProfileFailure = (props) =>
  action(UserActionItems.UPDATE_USERPROFILE_FAILURE, props);

// flags

export const clearFlag = (props) => action(UserActionItems.CLEAR_FLAGS, props);
