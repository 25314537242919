import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Col } from "react-bootstrap";
import NavLayout from "../layout/Nav";
import SideBar from "../layout/SideBar";

const AppRoute = () => {
  const isAuth = localStorage.getItem("isAuthenticated");
  const role = localStorage.getItem("role");
  const isAuthenticated = 1;

  if (!isAuthenticated) {
    localStorage.clear();
  }

  return (
    <>
      <main>
        {isAuth ? (
          role == 1 ? (
            <>
              <NavLayout />
              <div className="chat-container">
                {/* <Col className="h-100" > */}
                <Outlet />
              </div>
            </>
          ) : (
            <>
              <div className="h-100">
                <NavLayout />
                <div className="chat-container">
                  <Outlet />
                </div>
              </div>
            </>
          )
        ) : (
          <>
            <Navigate to="/home" />
          </>
        )}
      </main>
    </>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(AppRoute);
