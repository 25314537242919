import { call, put, takeEvery } from "redux-saga/effects";
import { UserActionItems } from "./Model";
import { signIn, signUp, updateuserProfile, userProfileData } from "./api";
import {
  UpdateUserProfileFailure,
  UpdateUserProfileSuccess,
  getUserProfileFailure,
  getUserProfileSuccess,
  loginFailure,
  loginSuccess,
  signupFailure,
  signupSuccess,
} from "./Action";

export function* handleSignup(action) {
  try {
    const response = yield call(signUp, action.payload);
    yield put(signupSuccess(response));
  } catch (error) {
    yield put(signupFailure(error));
  }
}

export function* handleLogin(action) {
  try {
    const response = yield call(signIn, action.payload);
    yield put(loginSuccess(response));
  } catch (error) {
    yield put(loginFailure(error));
  }
}

export function* handleGetUserProfile(action) {
  try {
    const response = yield call(userProfileData, action.payload);
    yield put(getUserProfileSuccess(response));
  } catch (error) {
    yield put(getUserProfileFailure(error));
  }
}

export function* UpdateUserProfile(action) {
  try {
    const response = yield call(updateuserProfile, action.payload);
    yield put(UpdateUserProfileSuccess(response));
  } catch (error) {
    yield put(UpdateUserProfileFailure(error));
  }
}

export function* userSaga() {
  yield takeEvery(UserActionItems.SIGNUP_REQUEST, handleSignup);
  yield takeEvery(UserActionItems.LOGIN_REQUEST, handleLogin);
  yield takeEvery(UserActionItems.USERPROFILE_REQUEST, handleGetUserProfile);
  yield takeEvery(UserActionItems.UPDATE_USERPROFILE_REQUEST, UpdateUserProfile);
}
