export const LawyerActionItems = {
  LOGIN_REQUEST: "@@lawyer/LOGIN_REQUEST",
  LOGIN_SUCCESS: "@@lawyer/LOGIN_SUCCESS",
  LOGIN_FAILURE: "@@lawyer/LOGIN_FAILURE",

  SIGNUP_REQUEST: "@@lawyer/SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "@@lawyer/SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "@@lawyer/SIGNUP_FAILURE",

  POSTCASEDETAIL_REQUEST: "@@lawyer/SENDCASEDETAIL_REQUEST",
  POSTCASEDETAIL_SUCCESS: "@@lawyer/SENDCASEDETAIL_SUCCESS",
  POSTCASEDETAIL_FAILURE: "@@lawyer/SENDCASEDETAIL_FAILURE",

  GETCASEDETAIL_REQUEST: "@@lawyer/GETCASEDETAIL_REQUEST",
  GETCASEDETAIL_SUCCESS: "@@lawyer/GETCASEDETAIL_SUCCESS",
  GETCASEDETAIL_FAILURE: "@@lawyer/GETCASEDETAIL_FAILURE",

  POSTSETTINGUPDATEDETAIL_REQUEST: "@@lawyer/SENDSETTINGUPDATEDETAIL_REQUEST",
  POSTSETTINGUPDATEDETAIL_SUCCESS: "@@lawyer/SENDSETTINGUPDATEDETAIL_SUCCESS",
  POSTSETTINGUPDATEDETAIL_FAILURE: "@@lawyer/SENDSETTINGUPDATEDETAIL_FAILURE",

  GETLAWYERPROFILEDETAIL_REQUEST: "@@lawyer/GETSETTINGUPDATEDETAIL_REQUEST",
  GETLAWYERPROFILEDETAIL_SUCCESS: "@@lawyer/GETSETTINGUPDATEDETAIL_SUCCESS",
  GETLAWYERPROFILEDETAIL_FAILURE: "@@lawyer/GETSETTINGUPDATEDETAIL_FAILURE",

  CLEAR_LAWYERMESSAGE: "@@lawyer/CLEAR_LAWYERMESSAGE",
};

export const lawyerInitialState = {
  loading: false,
  loginLoading: false,
  errorMsg: { error: false, message: "" },
  sendcdLoading: false,
  getcdLoading: false,
  postSetLoading: false,
  getSetLoading: false,
  caseDetailData: [],
  lawyerDetails: {},
  lawyerMessage: "",
  lawyerData: {},
};
