import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storageSession from "redux-persist/lib/storage/session";
import { HomeReducer } from "../components/Home/Reducer";
import { LawyerReducer } from "../components/Lawyer/Reducer";
import { userReducer } from "../components/User/Reducer";
import { UserActionItems } from "../components/User/Model";

const appReducer = combineReducers({
  HomeReducer,
  LawyerReducer,
  userReducer,
});

const persistConfig = {
  key: "talk_legal",
  storage: storageSession,
};
const rootReducer = (state, action) => {
  if (action.type === UserActionItems.LOGOUT) {
    state = undefined;
    localStorage.clear();
  }
  return appReducer(state, action);
};

export default persistReducer(persistConfig, rootReducer);
