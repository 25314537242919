import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Https";

export const signUp = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "lawyers", postData);
};
export const login = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "lawyer/login", postData);
};
/* Post & get Api for  Case Detail  */
export const postCaseDetail = (postData, id) => {
  console.log(postData,id, "234567yuhj")
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "lawyer/casedetail/"+postData.id, postData, Http.getFileAuthToken());
};

export const getCaseDetail = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "lawyer/casedetail/"+id, {} );
};

/* Post & Get Api for Setting Detail Update  */

export const postsettingDetail = (postData) => {

  console.log(postData.formData.email,postData.formData,"ghj56")
  const baseUrl = getBaseEndpointUrl();
  return Http.put(baseUrl + "lawyer/"+postData.formData.id, postData.formData, Http.getAuthToken());
};

export const getLawyerUserDetail = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "lawyer/"+postData, Http.getAuthToken());

};