import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AppRoute from "./routes/route";
import { authProtectedRoutes, publicRoutes } from "./routes/";
import "./App.css";
import NavLayout from "./layout/Nav";
import { Col, Container, Row } from "react-bootstrap";
import SideBar from "./layout/SideBar";
import { useEffect, useState } from "react";
import { socket } from "./services/socket";
// import "../src/assets/css/style.css"
// import useLayout from "./layout/Layout";

function App() {
  // useLayout();

  return (
    <Router>
      <Routes>
        <Route path="/" element={<AppRoute />}>
          {authProtectedRoutes.map((route, idx) => (
            <Route exact path={route?.path} element={<route.component />} />
          ))}
        </Route>
        {publicRoutes.map((route, idx) => (
          <>
            <Route exact path={route?.path} element={<route.component />} />
          </>
        ))}
        <Route
          path="*"
          element={
            <div className="text-center d-flex justify-content-center align-items-center s100vh">
              {" "}
              <b> Not Found !! </b>
            </div>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
