import { getBaseEndpointUrl } from "../../services/config";
import { Http } from "../../services/Https";

export const signUp = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "users", postData);
};
export const signIn = (postData) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "user/login", postData);
};

export const userProfileData = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "user/" + id, Http.getAuthToken());
};

export const updateuserProfile = ({ formData }) => {
  const id = formData?.id;
  console.log(formData, id);
  delete formData?.id;
  const baseUrl = getBaseEndpointUrl();
  return Http.put(baseUrl + "user/" + id, formData, Http.getAuthToken());
};
