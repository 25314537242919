import {
  ListItem,
  Card,
  ListItemButton,
  Modal,
  Box,
  Button,
} from "@mui/material";
import Textarea from "@mui/joy/Textarea";
import React, { useState } from "react";

function RelatedQueries() {
  const data = [
    { id: 0, value: 10, label: "Skill A" },
    { id: 1, value: 15, label: "Skill B" },
    { id: 2, value: 20, label: "Skill C" },
  ];

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    // border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  /* States */
  const [opinionModal, setOpinionModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [opinion, setOpinion] = useState(null);

  /* Fucntion */
  const handleOpinion = (index) => {
    setOpinionModal(true);
    setSelectedIndex(index);
  };

  const handleClose = () => {
    setOpinionModal(false);
    setOpinion(null);
  };

  const handleInput = (e) => {
    const { value } = e.target;
    setOpinion(value);
  };

  return (
    <div>
      <div className="card-body-style hr-style">
        <div>
          <h5>Related Queries</h5>
        </div>
        <hr />
        {data?.map((data, i) => {
          return (
            <>
              <ListItem
                onClick={() => handleOpinion(i)}
                component="div"
                disablePadding
              >
                <ListItemButton>
                  <h6>{data?.label}</h6>
                </ListItemButton>
              </ListItem>
            </>
          );
        })}
      </div>
      <Modal
        open={opinionModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: 400 }} className="shadow rounded-3">
          <div>
            <h5>
              Query :{" "}
              <span className="text-muted fs-6 fw-bold">
                {data[selectedIndex]?.label}
              </span>
            </h5>
            <div>
              <Textarea
                minRows={3}
                className="w-100 mt-2"
                id="standard-basic"
                placeholder="Your Opinion"
                value={opinion}
                onChange={handleInput}
              />
            </div>
            <div className="text-end mt-2">
              <Button onClick={handleClose}>Share Opinion</Button>
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default RelatedQueries;
