export const UserActionItems = {
  LOGIN_REQUEST: "@@user/LOGIN_REQUEST",
  LOGIN_SUCCESS: "@@user/LOGIN_SUCCESS",
  LOGIN_FAILURE: "@@user/LOGIN_FAILURE",

  SIGNUP_REQUEST: "@@user/SIGNUP_REQUEST",
  SIGNUP_SUCCESS: "@@user/SIGNUP_SUCCESS",
  SIGNUP_FAILURE: "@@user/SIGNUP_FAILURE",

  USERPROFILE_REQUEST: "@@user/USERPROFILE_REQUEST",
  USERPROFILE_SUCCESS: "@@user/USERPROFILE_SUCCESS",
  USERPROFILE_FAILURE: "@@user/USERPROFILE_FAILURE",

  UPDATE_USERPROFILE_REQUEST: "@@user/UPDATE_USERPROFILE_REQUEST",
  UPDATE_USERPROFILE_SUCCESS: "@@user/UPDATE_USERPROFILE_SUCCESS",
  UPDATE_USERPROFILE_FAILURE: "@@user/UPDATE_USERPROFILE_FAILURE",

  LOGOUT: "@@user/LOGOUT",
  CLEAR_FLAGS: "@@user/CLEAR_FLAGS",
};

export const userrInitialState = {
  signupLoading: false,
  loginLoading: false,
  errorMsg: { error: false, message: "" },
  profileLoading: false,
  userData: [],
  loginResponse: "",
  isSignedUp: false,
  userMessage: "",
};

export const userSelector = (state) => state.userReducer;
