import {
  Alert,
  IconButton,
  Pagination,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { Button, Card, CardBody, Col, Form, Row } from "react-bootstrap";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { FaDownload } from "react-icons/fa";
import { useEffect, useRef, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { caseDetailValidator } from "./Validator";
import { useForm } from "react-hook-form";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { lawyerSelector } from "./Selector";
import {
  clearLawyerMessage,
  getCaseDetailRequest,
  postCaseDetailRequest,
} from "./Action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFile, faPlus } from "@fortawesome/free-solid-svg-icons";
import { IoCloseCircleOutline } from "react-icons/io5";
import { getKeyByValue, lawyerType, messages } from "../../utils/messages";

const CaseDetails = () => {
  const dispatch = useDispatch();
  const id = localStorage.getItem("id");

  const { lawyerMessage, caseDetailData, errorMsg } =
    useSelector(lawyerSelector);
  console.log(caseDetailData, "fvgnjk45");
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(caseDetailValidator),
  });
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  /* States */
  const [getType, setGeType] = useState(false);
  const [getStatus, setGetStatus] = useState([]);
  const [errorMsg1, seterrorMsg1] = useState({
    type: false,
    status: false,
  });

  const [file, setFile] = useState([]);

  const itemsPerPage = 9;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePaginationChange = (event, value) => {
    setCurrentPage(value);
  };
  /* Function */
  const handleCheck = () => {
    if (!getType || !getStatus) {
      seterrorMsg1({
        type: true,
        status: !getStatus,
      });
    } else {
      seterrorMsg1({
        type: false,
        status: false,
      });
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files) {
      const files = e.target.files;
      setFile([...files]);
    }
  };
  const onSubmitHandler = (formData) => {
    formData.type = getType;
    formData.status = getStatus;
    formData.case_file = file;
    console.log(formData, "dfd");
    dispatch(postCaseDetailRequest({ formData, id }));
  };

  const handleClose = () => {
    dispatch(clearLawyerMessage());
  };

  useEffect(() => {
    if (lawyerMessage == messages.case_details_updated) {
      dispatch(getCaseDetailRequest(id));
      reset();
    }
  }, [lawyerMessage]);

  useEffect(() => {
    dispatch(getCaseDetailRequest(id));
  }, [id]);

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <IoCloseCircleOutline fontSize="small" className="fs-4" />
      </IconButton>
    </>
  );
  return (
    <>
      <section className="case-details-sec">
        <Snackbar
          open={lawyerMessage == "Case Details uploaded successfully"}
          autoHideDuration={5000}
          onClose={handleClose}
          message={lawyerMessage}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {lawyerMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMsg?.error}
          autoHideDuration={5000}
          onClose={handleClose}
          message={errorMsg?.message}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errorMsg?.message}
          </Alert>
        </Snackbar>
        <Row>
          <Col lg={3} sm={12} md={6}>
            <div className="card-body-style case-details-form">
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Row>
                  <Col className={"p-2"} md={12}>
                    <h5>Case Details</h5>
                    <hr />
                  </Col>
                  <Col className={"p-2"} md={12}>
                    <div className="input-container">
                      <TextField
                        className="w-100"
                        id="standard-basic"
                        label="Case Detail"
                        variant="outlined"
                        {...register("case_detail")}
                      />
                      <div>
                        {errors?.case_detail && (
                          <p className="text-danger mt-1">
                            {errors?.case_detail?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col className={"p-2"} md={12}>
                    <label className="label-style-form">Type</label>
                    <div className="d-flex align-items-center px-2">
                      <div className="pe-2">Criminal</div>
                      <AntSwitch
                        value={getKeyByValue(lawyerType, getType)}
                        inputProps={{ "aria-label": "ant design" }}
                        onChange={(e) => {
                          console.log(e);
                          const { checked } = e.target;
                          setGeType(lawyerType[checked]);
                        }}
                      />
                      <div className="ms-2">Civil</div>
                    </div>
                    {errorMsg1?.type && (
                      <p className="text-danger"> Select Type</p>
                    )}
                  </Col>
                  <Col className={"p-2"} md={12}>
                    <FormControl>
                      <FormLabel
                        className="label-style-form"
                        id="demo-row-radio-buttons-group-label"
                      >
                        Status
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        defaultValue="solved"
                        name="radio-buttons-group"
                        className="px-2"
                      >
                        <FormControlLabel
                          value={1}
                          control={<Radio />}
                          label="Solved"
                          onChange={(e) => setGetStatus("Solved")}
                        />
                        <FormControlLabel
                          value={2}
                          control={<Radio />}
                          label="Un Solved"
                          name="Un Solved"
                          onChange={(e) => setGetStatus("Un Solved")}
                        />
                        <FormControlLabel
                          value={3}
                          name=""
                          control={<Radio />}
                          label="Declined"
                          onChange={(e) => setGetStatus("Declined")}
                        />
                      </RadioGroup>
                      {errorMsg1?.status && (
                        <p className="text-danger"> Select Status</p>
                      )}
                    </FormControl>
                  </Col>
                  <Col>
                    <div className="d-flex justify-content-between">
                      <label className="label-style-form">Case File</label>
                      {/* <Tooltip title="Download Template" placement="top">
                        <Button variant="contained">
                          <FaDownload /> 
                        </Button>
                      </Tooltip> */}
                    </div>
                    <div className="bg-light file-margin p-2 rounded-3">
                      <div className="addimage-btn">
                        <div>
                          {/* <FontAwesomeIcon
                              className="btn-iconstyle"
                              icon={faPlus}
                              type="files"
                              onChange={(e)=>setFile(e.target.files)}
                            /> */}
                          <input
                            type="file"
                            accept="any"
                            multiple
                            onChange={handleFileChange}
                          ></input>
                          {file.length > 0 ? (
                            <ul>
                              {file.map((file, index) => (
                                <li key={index}>{file.name}</li>
                              ))}
                            </ul>
                          ) : (
                            <p>No files selected</p>
                          )}
                        </div>
                      </div>
                    </div>
                  </Col>

                  <Col className={"p-2"} md={12}>
                    <div className="input-container">
                      <TextField
                        className="w-100"
                        id="standard-basic"
                        label="Case Description"
                        variant="outlined"
                        {...register("case_description")}
                      />
                      <div>
                        {errors?.case_description && (
                          <p className="text-danger mt-1">
                            {errors?.case_description?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </Col>
                  <Col className={"p-2"} md={12}>
                    <div className="input-container">
                      <TextField
                        className="w-100"
                        id="standard-basic"
                        label="Court Location"
                        variant="outlined"
                        {...register("case_location")}
                      />
                      <div>
                        {errors?.case_location && (
                          <p className="text-danger mt-1">
                            {errors?.case_location?.message}
                          </p>
                        )}
                      </div>
                    </div>
                  </Col>

                  <div className="text-end">
                    <Button
                      className="submit-btn"
                      type="submit"
                      onClick={handleCheck}
                    >
                      Submit
                    </Button>
                  </div>
                </Row>
              </form>
            </div>
          </Col>
          <Col lg={9} md={6} sm={12}>
            <div className="card-body-style mar-top-res">
       
            <Row>
                {caseDetailData
                  .slice(
                    (currentPage - 1) * itemsPerPage,
                    currentPage * itemsPerPage
                  )
                  .map((ele, index) => (
                    <Col key={index} md={4}>
                     <div className="case-card-de">
                        <div>
                          <h5>
                            <FontAwesomeIcon
                              className="case-file-i"
                              icon={faFile}
                            />{" "}
                            {ele.case_detail}
                          </h5>
                        </div>
                        <div className="flex-casetype">
                          <p className="wid-50">
                            <span>Type :</span> {ele.type}
                          </p>
                          <p className="wid-50">
                            <span>Status :</span> {ele.status}
                          </p>
                        </div>
                        <p className="p-align">
                          <span>Description :</span>
                          {ele.case_description}
                        </p>
                        <div className="pdf-download">
                          <FontAwesomeIcon
                            className="download-icon"
                            icon={faDownload}
                          />{" "}
                          <span>first-case.pdf </span>{" "}
                        </div>
                      </div>
                    </Col>
                  ))}
              </Row>

              <div className="d-flex justify-content-end mt-2">
                <Pagination 
                count={Math.ceil(caseDetailData.length / itemsPerPage)}
                page={currentPage}
                color="primary"
                onChange={handlePaginationChange}  />
              </div>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default CaseDetails;
