export const messages = {
  get_lawyerDeatils: "User Details Retreived Successfully",
  get_userDetails: "User Details Retreived Succesfully",
  userDetails_updated: "Updated Successfully",
  userUpdateFail: "Something Went Wrong",
  getuserDetailFailed: "User Detail Failed",
  case_details_updated: "Case Details uploaded successfully",
};

export const lawyerType = {
  true: "Civil",
  false: "Criminal",
};

export function getKeyByValue(object, value) {
  for (let prop in object) {
    if (object.hasOwnProperty(prop)) {
      if (object[prop] === value) return prop;
    }
  }
}
