import { Http } from "../../services/Https";
import { getBaseEndpointUrl } from "../../services/config";

export const askQues = (data) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.post(baseUrl + "query", data);
};

export const chathistory = (id) => {
  const baseUrl = getBaseEndpointUrl();
  return Http.get(baseUrl + "chat_history/" + id, Http.getAuthToken());
};
