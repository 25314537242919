import { Button, Col, Container, Row } from "react-bootstrap";
import { IoChatbubbleEllipsesOutline } from "react-icons/io5";
import { AiOutlineSend } from "react-icons/ai";
import Chat from "../Chat/Chat";
import { useEffect, useState } from "react";
// import { socket } from "../../services/socket";
import Default from "../Default";
import SideBar from "../../layout/SideBar";
import NavLayout from "../../layout/Nav";
import { useDispatch, useSelector } from "react-redux";
import { askQusRequest, getchatHistoryRequest } from "./Action";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGavel } from "@fortawesome/free-solid-svg-icons";
import { homeSelector } from "./Selector";
import { Skeleton } from "@mui/material";
import { socket } from "../../services/socket";

const Home = () => {
  const dispatch = useDispatch();
  const [emitData, setEmit] = useState("");
  const isAuth = localStorage.getItem("isAuthenticated");
  const user_id = localStorage.getItem("user_id");
  const { history_load, historyData, homeMessage } = useSelector(homeSelector);
  const [query, setQuery] = useState(null);
  const [messages, setMessages] = useState([]);
  const [skeleton, setSkeleton] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [stopProp, setStopProp] = useState(false);
  const [test, setTest] = useState(false);

  console.log(messages, isAuth, "messages");

  const handleQuery = (e) => {
    const { value } = e.target;
    setQuery(value);
  };

  // useEffect(() => {
  //   socket.on("message", ({ response, msg }) => {
  //     console.log(response, "idufhsufho");
  //     setMessages((prevMessages) => [...prevMessages, response]);
  //     setQuestion(msg);
  //     setLoading(true);
  //   });
  //   setStopProp(false);

  //   setIsAuth(localStorage.getItem("isAuthenticated"));

  //   return () => {
  //     socket.off("message");
  //   };
  // }, [test]);

  const handleSubmit = () => {
    
    const formData = {
      query,
      user_id,
      socket_id: "54asdf",
    };
    setEmit(query);
    dispatch(askQusRequest(formData));
    // socket.emit("message", query);
    setQuery("");
  };

  const handlestopReq = () => {
    socket.on("disconnect", (data) => {});
    setStopProp(true);
  };

  useEffect(() => {
    if (homeMessage == "Query posted successfully") {
      dispatch(getchatHistoryRequest(user_id));
    }
  }, [homeMessage, user_id]);

  window.addEventListener("unload", () => {
    socket.on("disconnect", (data) => {
      console.log('first')
    });
    console.log("API call after page reload");
  });

  return (
    <Row className="h-100">
      <Col>
        {!isAuth ? (
          <>
            <NavLayout />
          </>
        ) : (
          <></>
        )}

        <div className="chat-container">
          <div className="chat-part">
            {/* {messages?.length > 0 ? (
              <div className="w-100 h-100">
                {messages?.map((mess) => {
                  return ( */}
            <Row className="chat-screen">
              <Col md={3}></Col>
              <Col md={6}>
                <Chat
                  message={messages}
                  // question={question}
                  loading={setLoading}
                  speed={10}
                  isStop={skeleton}
                />
              </Col>
              {isAuth && (
                <Col className="bg-width position" md={3}>
                  <div className="bg">
                    <div className="history">
                      <ul>
                        {history_load &&
                          [1, 2].map((data) => (
                            <>
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem", width: "50%" }}
                                animation={"wave"}
                              />
                              <hr />
                              <Skeleton
                                variant="text"
                                sx={{ fontSize: "1rem" }}
                                animation={"wave"}
                              />
                            </>
                          ))}
                        {historyData?.map((history, index) => {
                          return (
                            <>
                              <div className="history-content">
                                {/* <span>Today</span> */}
                                {/* <hr /> */}
                                <li>
                                  <FontAwesomeIcon
                                    className="law"
                                    icon={faGavel}
                                  />
                                  {history?.query}
                                </li>
                              </div>
                            </>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                </Col>
              )}
            </Row>
            {/* );
                })} */}
            {/* </div>
            ) : (
              <Default />
            )} */}
            <div className="d-flex justify-content-center">
              <div className="privacy">
                <ul>
                  <li>Privacy</li>
                  <li>Terms</li>
                  <li>Policy</li>
                </ul>
              </div>
              <div className="chat-text-box text-center">
                {/* {isLoading && (
                  <Button
                    variant="contained"
                    className="mb-2 text-center"
                    onClick={handlestopReq}
                  >
                    Stop Recognizing
                  </Button>
                )} */}
                <div className="d-flex align-items-center">
                  <div className="newchat">
                    <IoChatbubbleEllipsesOutline
                      className="message"
                      size={30}
                    />
                  </div>
                  <div className="w-100 d-flex align-items-center p-2 rounded-3 mx-2">
                    <textarea
                      name="chat"
                      rows={2}
                      value={query}
                      className="w-100 rounded-3 mx-3 p-2 border-0 chat-bar"
                      placeholder={`Type message ...`}
                      onChange={handleQuery}
                    ></textarea>
                    <button 
                     className="send pointer-cursor d-flex justify-content-center align-items-center bg-light"
                     size={20}onClick={handleSubmit}>
                    <AiOutlineSend
                    />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </Col>
    </Row>
  );
};

export default Home;
