import { Alert, IconButton, Rating, Snackbar } from "@mui/material";
import { Card, Col, Row } from "react-bootstrap";
import { FaUserCircle } from "react-icons/fa";
import { PieChart } from "@mui/x-charts/PieChart";

import { useEffect, useState } from "react";
import RelatedQueries from "./RelatedQueries";
import Enquiry from "./Enquiry";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faLocationDot,
  faStar,
  faStarHalf,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { clearLawyerMessage, getLawyerProfileRequest } from "./Action";
import { useNavigate } from "react-router-dom";
import { lawyerSelector } from "./Selector";
import { IoCloseCircleOutline } from "react-icons/io5";

const LawyerDashboard = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const id = localStorage.getItem("id");
  const { lawyerData, lawyerMessage } = useSelector(lawyerSelector);

  const data = [
    { id: 0, value: 10, label: "Skill A" },
    { id: 1, value: 15, label: "Skill B" },
    { id: 2, value: 20, label: "Skill C" },
  ];

  const probData = [
    { id: 0, value: 40, label: "Solved", person: "Vibin" },
    { id: 1, value: 10, label: "Un Solved", person: "Kishor" },
  ];

  /* State */
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  /* Function */
  const handleClose = () => {
    setIsLoggedIn(false);
    dispatch(clearLawyerMessage());
  };

  useEffect(() => {
    dispatch(getLawyerProfileRequest(id));
  }, [id]);

  useEffect(() => {
    if (lawyerMessage == "Lawyer Sign in successfully!") {
      setIsLoggedIn(true);
      dispatch(clearLawyerMessage());
    }
  }, [lawyerMessage]);

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <IoCloseCircleOutline fontSize="small" className="fs-4" />
      </IconButton>
    </>
  );
  return (
    <>
      <div className="lawyer-dashboard">
        <Snackbar
          open={isLoggedIn}
          autoHideDuration={5000}
          onClose={handleClose}
          message={lawyerMessage}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {lawyerMessage}
          </Alert>
        </Snackbar>
        <Row>
          <Col xl={3} lg={6} md={12} sm={12}>
            <div className="card-body-style">
              <div className="borderhr">
                <Row className="content-center">
                  <Col md={12}>
                    <FontAwesomeIcon className="profile-user" icon={faUser} />
                  </Col>
                  <Col md={12}>
                    <h4> {lawyerData?.name ? lawyerData?.name : "-"}</h4>
                  </Col>
                  <Col md={12}>Skill Points</Col>
                  <Col md={12}>
                    <Rating precision={0.5} readOnly defaultValue={3.5} />
                  </Col>
                </Row>
                <hr />
              </div>
              <div className="location-details">
                <Row>
                  <Col md={5}>
                    {" "}
                    <h5>Email</h5>{" "}
                  </Col>
                  <Col md={7}>
                    <p>
                      <span>:</span>
                      {lawyerData?.email ? lawyerData?.email : "-"}
                    </p>
                  </Col>
                  <Col md={5}>
                    {" "}
                    <h5>BarReg no</h5>{" "}
                  </Col>
                  <Col md={7}>
                    <p>
                      <span>:</span>
                      {lawyerData?.bar_no ? lawyerData?.bar_no : "-"}
                    </p>
                  </Col>
                  <Col md={5}>
                    {" "}
                    <h5>Enroll no</h5>{" "}
                  </Col>
                  <Col md={7}>
                    <p>
                      <span>:</span>
                      {lawyerData?.enroll_no ? lawyerData?.enroll_no : "-"}
                    </p>
                  </Col>
                  <Col md={5}>
                    {" "}
                    <h5>Location</h5>{" "}
                  </Col>
                  <Col md={7}>
                    <p>
                      <span>:</span>
                      {lawyerData?.district ? lawyerData?.district : "-"},
                      {lawyerData?.state ? lawyerData?.state : "-"}
                    </p>
                  </Col>
                  {/* <Col md={5}> <h5><FontAwesomeIcon className="location-i" icon={faLocationDot} /> City </h5></Col>
                      <Col md={7}> <p>Chennai, Tamila Nadu </p> </Col> */}
                </Row>
                <div className="info-card">
                  <PieChart
                    series={[
                      {
                        data,
                        highlightScope: {
                          faded: "global",
                          highlighted: "item",
                        },
                        faded: {
                          innerRadius: 30,
                          additionalRadius: -30,
                          color: "gray",
                        },
                      },
                    ]}
                    height={200}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={6} md={12} sm={12}>
            <Row>
              <Col lg={6} md={12}>
                <div className="card-body-style dashboard-card">
                  <Row className="spacebet-res">
                    <Col md={2} className="align-self">
                      <FontAwesomeIcon className="file-icon" icon={faFile} />
                    </Col>
                    <Col md={10} lg={8}>
                      <h6>
                        {" "}
                        {lawyerData?.cases_total
                          ? lawyerData?.cases_total
                          : "-"}
                      </h6>
                      <span>No. of Total Cases</span>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="card-body-style dashboard-card">
                  <Row className="spacebet-res">
                    <Col md={2} className="align-self">
                      <FontAwesomeIcon className="file-icon" icon={faFile} />
                    </Col>
                    <Col md={10} lg={8}>
                      <h6>
                        {lawyerData?.criminal_cases
                          ? lawyerData?.criminal_cases
                          : "-"}
                      </h6>
                      <span>No. of Criminal Cases</span>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="card-body-style dashboard-card">
                  <Row className="spacebet-res">
                    <Col md={2} className="align-self">
                      <FontAwesomeIcon className="file-icon" icon={faFile} />
                    </Col>
                    <Col md={10} lg={8}>
                      <h6>
                        {lawyerData?.cases_won ? lawyerData?.cases_won : "-"}
                      </h6>
                      <span>No. of Solved Cases</span>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="card-body-style dashboard-card">
                  <Row className="spacebet-res">
                    <Col md={2} className="align-self">
                      <FontAwesomeIcon className="file-icon" icon={faFile} />
                    </Col>
                    <Col md={10} lg={8}>
                      <h6>{lawyerData?.civil_cases
                          ? lawyerData?.civil_cases
                          : "-"}</h6>
                      <span>No. of Civil Cases</span>
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <RelatedQueries />
              </Col>
              <Col lg={6} md={12}>
                <Enquiry />
              </Col>
            </Row>
          </Col>
          <Col xl={3} lg={6} md={12} sm={12}>
            <div className="card-body-style card-1024res">
              <h5>Solved Probability</h5>
              <hr />
              <PieChart
                series={[
                  {
                    data: probData,
                    highlightScope: {
                      faded: "global",
                      highlighted: "item",
                    },
                    faded: {
                      innerRadius: 30,
                      additionalRadius: -30,
                      color: "gray",
                    },
                  },
                ]}
                height={200}
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LawyerDashboard;
