import * as yup from "yup";

export const lawyerSignUpValidator = yup.object().shape({
  name: yup.string().required("Name is Required"),
  email: yup.string().required("Email is Required"),
  mobile_no: yup.string().required("Phone Number is Required"),
  state: yup.string().required("State is Required"),
  district: yup.string().required("District is Required"),
  bar_no: yup.string().required("BarCouncil Reg No is Required"),
  enroll_no: yup.string(),
  password: yup
    .string()
    .required("Password is required")
    .min(4, "Password length should be at least 4 characters")
    .max(12, "Password cannot exceed more than 12 characters"),
  confirmpasswrd: yup
    .string()
    .required("Confirm Password is required")
    .min(4, "Password length should be at least 4 characters")
    .max(12, "Password cannot exceed more than 12 characters")
    .oneOf([yup.ref("password")], "Passwords do not match"),
});

export const lawyerSigninValidator = yup.object().shape({
  email: yup.string().required("Email/Phone no. is Required"),
  password: yup.string().required("Password is Required"),
});

export const profileValidator = yup.object().shape({
  name: yup.string().required("Name is Required"),
  email: yup.string().email().required("Email is Required"),
  mobile_no: yup.number().required("Phone number is Required"),
  bar_no: yup
    .string()
    // .matches(/^[0-9]{10}$/, "Bar code must be exactly 10 digits")
    .required("Bar Registration No. is Required"),
  enroll_no: yup
    .string()
    // .matches(
    //   /^[A-Z]{2}\d{4}$/,
    //   "Enrollment number must be in the format: two uppercase letters followed by four digits"
    // )
    .nullable(true),
  state: yup.string().required("State is Required"),
  district: yup.string().required("District is Required"),
  cases_total: yup.number().required("No Of Cases Required"),
  cases_won: yup.number().required("Won Cases Required"),
  cases_loss: yup.number().required(" Loss Cases Required"),
  criminal_cases: yup.number().required("Won Cases Required"),
  civil_cases: yup.number().required(" Loss Cases Required"),
});

/* Case Detail Validator */

export const caseDetailValidator = yup.object().shape({
  case_detail: yup.string().required("Case Detail Required"),
  case_description: yup.string().required("Case Description Required"),
  case_location: yup.string().required("Case Location Required"),
});
