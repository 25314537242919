import { Button, Col, Row } from "react-bootstrap";
import { SiChatbot } from "react-icons/si";
import { FaUserCircle } from "react-icons/fa";
import { RxHamburgerMenu } from "react-icons/rx";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";
import Logo from "../assets/image/logo.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { RiUserSettingsFill } from "react-icons/ri";
import { IoLogOut } from "react-icons/io5";
import { MdPrivacyTip } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { HiMiniDocumentText } from "react-icons/hi2";
import { useDispatch } from "react-redux";
import { logoutRequest } from "../components/User/Action";

const NavLayout = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isAuth = localStorage.getItem("isAuthenticated");
  const role = localStorage.getItem("role");

  /* States */
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");

  const openM = Boolean(anchorEl);

  /* Fucntion */

  const handleClose = () => {
    setOpen(false);
  };

  const handleSignin = () => {
    if (role == 2) {
      navigate("/lawyer/signin");
    } else {
      navigate("/signin");
    }
    setAnchorEl(null);
  };

  const handleNavigate = () => {
    if (role == 1) {
      navigate("/setting");
    } else {
      navigate("/lawyer/settings");
    }
    setAnchorEl(null);
  };

  const handleCloseM = () => {
    setAnchorEl(null);
  };

  const handleCase = () => {
    navigate("/lawyer/case-details");
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setAnchorEl(null);
    if (role == 1) navigate("/home");
    else navigate("/lawyer/signin");
    dispatch(logoutRequest());
  };

  const handleRegister = () => {
    localStorage.setItem("isAuthenticated", true);
    localStorage.setItem("name", name);
  };

  const handleSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <div className="p-3 pt-4 head-bg align-items-center">
      <Row>
        <Col md={3}>
          <h4 className="px-3">
            <img src={Logo} alt="" width={200} />
          </h4>
        </Col>
        <Col></Col>
        <Col md={5}>
          <div className="d-flex nav align-items-center justify-content-end">
            {localStorage.getItem("isAuthenticated") ? (
              <>
                <h6 className="px-2 mb-0">{localStorage.getItem("name")}</h6>
                <FaUserCircle className="signin" size={20} />
              </>
            ) : (
              <>
                <Button variant="contained px-2 mb-0" onClick={handleSignin}>
                  Signin <FaUserCircle size={20} />
                </Button>
              </>
            )}
            {isAuth && (
              <RxHamburgerMenu
                size={20}
                className="mx-3 pointer-cursor"
                onClick={handleSettings}
              />
            )}
          </div>
        </Col>
      </Row>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
            navigate("/");
          },
        }}
      >
        <DialogTitle>
          <img src={Logo} alt="" width={110} srcset="" />
          <div className="mt-2">Signin</div>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To subscribe to this website, please enter your email address here.
            We will send updates occasionally.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="email"
            label="Email Address"
            type="email"
            fullWidth
            variant="standard"
            onChange={(e) => setName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="contained" type="submit" onClick={handleRegister}>
            Signin
          </Button>
        </DialogActions>
      </Dialog>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openM}
        onClose={handleCloseM}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem onClick={handleNavigate}>
          <RiUserSettingsFill className="text-secondary" />{" "}
          <span className="mx-3">Settings</span>
        </MenuItem>
        {role == 1 ? (
          <></>
        ) : (
          <MenuItem onClick={handleCase}>
            <HiMiniDocumentText className="text-secondary" />
            <span className="mx-3">Case</span>
          </MenuItem>
        )}
        <MenuItem onClick={handleCloseM}>
          {" "}
          <MdPrivacyTip className="text-secondary" />{" "}
          <span className="mx-3">Privacy</span>
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <IoLogOut className="text-secondary" />{" "}
          <span className="mx-3">Logout</span>
        </MenuItem>
      </Menu>
      <div className="d-flex justify-content-center">
        <hr className="w-25 hr-shadow" />
      </div>
    </div>
  );
};

export default NavLayout;
