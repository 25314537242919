import { Button, Card, CardBody, Col, Row,Form } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Alert, Grid, IconButton, TextField } from "@mui/material";
import Logo from "../../assets/image/logo.png";
import { signinUserValidator } from "./Validator.js";
import { useDispatch, useSelector } from "react-redux";
import { clearFlag, loginRequest } from "./Action.js";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { userSelector } from "./Model.js";
import Snackbar from "@mui/material/Snackbar";
import { IoCloseCircleOutline } from "react-icons/io5";

const Signin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const { loginResponse, errorMsg } = useSelector(userSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(signinUserValidator),
  });

  console.log(errors);

  const onSubmitHandler = (formData) => {
    console.log(formData);
    dispatch(loginRequest(formData));
  };

  useEffect(() => {
    if (loginResponse == "Login Successfully") {
      setIsLoggedIn(true);
      navigate("/");
    } else {
      setIsLoggedIn(false);
    }
  }, [loginResponse]);

  const handleClose = () => {
    setIsLoggedIn(false);
    dispatch(clearFlag());
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <IoCloseCircleOutline fontSize="small" className="fs-4" />
      </IconButton>
    </>
  );

  return (
    <>
      <div className="h-100 w-100 d-flex justify-content-center align-items-center">
        <Snackbar
          open={isLoggedIn}
          autoHideDuration={5000}
          onClose={handleClose}
          message={loginResponse}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {loginResponse}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMsg?.error}
          autoHideDuration={5000}
          onClose={handleClose}
          message={errorMsg?.message}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errorMsg?.message}
          </Alert>
        </Snackbar>
        <Card className="rounded-3 p-5 shadow">
          <CardBody>
            <div>
              <div className="logo-width text-center">
                <img src={Logo} alt="" className="d-block mb-4" />
                <h3 className="login">Login</h3>
              </div>
              
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                {/* <div>
                  <Row className="mt-1 p-3">
                    <Col md={12}>
                      <div className="input-container">
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Email"
                          variant="standard"
                          {...register("email")}
                        />
                        <div>
                          {errors?.email && (
                            <p className="text-danger mt-1">
                              {errors?.email?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-1 p-3">
                    <Col md={12}>
                      <div className="input-container">
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Pssword"
                          variant="standard"
                          type="password"
                          {...register("password")}
                        />
                        {errors?.password && (
                          <p className="text-danger mt-1">
                            {errors?.password?.message}
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Grid item sm al textAlign={"right"}>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </div>
                <div className="text-center mt-1">
                  <Button type="submit">Login</Button>
                </div>
                <Grid container className="text-center">
                  <Grid item sm>
                    <Link to="/signup" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </form> */}
                  <div className="form-space">
                    <TextField
                      className="w-100"
                      id="standard-basic"
                      label="Email"
                      variant="outlined"
                      {...register("email")}
                    />
                    {errors?.email && (
                            <p className="text-danger mt-1">
                              {errors?.email?.message}
                            </p>
                          )}
                  </div>
                  <div>
                    <TextField
                      className="w-100"
                      id="standard-basic"
                      label="Password"
                      variant="outlined"
                      type="password"
                      {...register("password")}
                    />
                     {errors?.password && (
                          <p className="text-danger mt-1">
                            {errors?.password?.message}
                          </p>
                        )}
                  </div>

                  <div className="text-center mt-1">
                    <Button className="submit-btn" type="submit">Login</Button>
                  </div>

                  <div className="text">
                    <p>Don't have an account? <span><Link to="/signup">Sign Up</Link></span></p>
                  </div>
                  </form>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default Signin;
