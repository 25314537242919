import * as yup from "yup";

export const userSignUp = yup.object().shape({
  name: yup.string().required("Name is Required"),
  email: yup.string().required("Email is Required"),
  mobile_no: yup.string().required("Phone Number is Required"),
  password: yup
    .string()
    .required("Password is required")
    .min(4, "Password length should be at least 4 characters")
    .max(12, "Password cannot exceed more than 12 characters"),
  confirmpasswrd: yup
    .string()
    .required("Confirm Password is required")
    .min(4, "Password length should be at least 4 characters")
    .max(12, "Password cannot exceed more than 12 characters")
    .oneOf([yup.ref("password")], "Passwords do not match"),
});

export const signinUserValidator = yup.object().shape({
  email: yup.string().required("Email/Phone no. is Required"),
  password: yup.string().required("Phone Number is Required"),
});

export const profileValidator = yup.object().shape({
  name: yup.string().required("Name is Required"),
  email: yup.string().required("Email is Required"),
  mobile_no: yup.string().required("Phone number is Required"),
});
