import Home from "../components/Home/Home";
import CaseDetails from "../components/Lawyer/CaseDetails";
import LawyerDashboard from "../components/Lawyer/Dashboard";
import LawyerSettings from "../components/Lawyer/LawyerSettings";
import LawyerSignin from "../components/Lawyer/LawyerSignin";
import LawyerSignup from "../components/Lawyer/LawyerSignup";
import Signup from "../components/User/SignUp";
import Signin from "../components/User/Signin";
import UserSettings from "../components/User/UserSettings";
const isAuth = localStorage.getItem("isAuthenticated");

var authProtectedRoutes = [
  { path: "/", component: Home },
  { path: "/setting", component: UserSettings },
  { path: "/lawyer/dashboard", component: LawyerDashboard },
  { path: "/lawyer/settings", component: LawyerSettings },
  { path: "/lawyer/case-details", component: CaseDetails },
];

// const data = isAuth
//   ? [...authProtectedRoutes, { path: "/home", component: Home }]
//   : authProtectedRoutes.filter((ele) => {
//       if (ele.path != "/home") return ele;
//     });

// authProtectedRoutes = data;

const publicRoutes = [
  { path: "/home", component: Home },
  { path: "/lawyer/signup", component: LawyerSignup },
  { path: "/lawyer/signin", component: LawyerSignin },
  { path: "/signup", component: Signup },
  { path: "/signin", component: Signin },
];

export { authProtectedRoutes, publicRoutes };
