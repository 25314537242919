import { Button, Card, CardBody, Col, Row } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { Alert, Grid, IconButton, Snackbar, TextField } from "@mui/material";
import Logo from "../../assets/image/logo.png";
import { lawyerSigninValidator } from "./Validator";
import { useDispatch, useSelector } from "react-redux";
import { clearLawyerMessage, lawyerloginRequest } from "./Action";
import { Link, useNavigate } from "react-router-dom";
import { lawyerSelector } from "./Selector";
import { useEffect, useState } from "react";
import { IoCloseCircleOutline } from "react-icons/io5";

const LawyerSignin = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { lawyerMessage, errorMsg } = useSelector(lawyerSelector);
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(lawyerSigninValidator),
  });

  console.log(errors);
  /* State */
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  /* Fucntion */
  const onSubmitHandler = (formData) => {
    console.log(formData);
    dispatch(lawyerloginRequest(formData));
  };

  useEffect(() => {
    if (lawyerMessage == "Lawyer Sign in successfully!")
      navigate("/lawyer/dashboard");
  }, [lawyerMessage]);

  const handleClose = () => {
    setIsLoggedIn(false);
    dispatch(clearLawyerMessage());
  };

  const action = (
    <>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <IoCloseCircleOutline fontSize="small" className="fs-4" />
      </IconButton>
    </>
  );

  return (
    <>
      <div className="h-100 w-100 d-flex justify-content-center align-items-center">
        <Snackbar
          open={isLoggedIn}
          autoHideDuration={5000}
          onClose={handleClose}
          message={lawyerMessage}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {lawyerMessage}
          </Alert>
        </Snackbar>
        <Snackbar
          open={errorMsg?.error}
          autoHideDuration={1000}
          onClose={handleClose}
          message={errorMsg?.message}
          action={action}
        >
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: "100%" }}
          >
            {errorMsg?.message}
          </Alert>
        </Snackbar>

        {/* <Snackbar
          open={lawyerMessage == "Lawyer Sign in successfully!"}
          autoHideDuration={6000}
          //   onClose={handleClose}
          message={lawyerMessage}
          //   action={action}
        /> */}

        <Card className="rounded-3 p-5 shadow">
          <CardBody>
            <div>
              <div className="logo-width text-center">
                <img src={Logo} alt="" className="d-block mb-4" />
                <h3 className="login">Laywer Login</h3>
              </div>
              {/* <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div>
                  <Row className="mt-1 p-3">
                    <Col md={12}>
                      <div className="input-container">
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Email"
                          variant="standard"
                          {...register("email")}
                        />
                        <div>
                          {errors?.email && (
                            <p className="text-danger mt-1">
                              {errors?.email?.message}
                            </p>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-1 p-3">
                    <Col md={12}>
                      <div className="input-container">
                        <TextField
                          className="w-100"
                          id="standard-basic"
                          label="Password"
                          variant="standard"
                          type="password"
                          {...register("password")}
                        />
                        {errors?.password && (
                          <p className="text-danger mt-1">
                            {errors?.password?.message}
                          </p>
                        )}
                      </div>
                    </Col>
                  </Row>
                  <Grid item sm al textAlign={"right"}>
                    <Link href="#" variant="body2">
                      Forgot password?
                    </Link>
                  </Grid>
                </div>

                <div className="text-center mt-1">
                  <Button type="submit">Login</Button>
                </div>
                <Grid container className="text-center">
                  <Grid item sm>
                    <Link to="/lawyer/signup" variant="body2">
                      {"Don't have an account? Sign Up"}
                    </Link>
                  </Grid>
                </Grid>
              </form> */}
              <form onSubmit={handleSubmit(onSubmitHandler)}>
                <div className="form-space">
                  <TextField
                    className="w-100"
                    id="standard-basic"
                    label="Email"
                    variant="outlined"
                    {...register("email")}
                  />
                </div>
                <div>
                  <TextField
                    className="w-100"
                    id="standard-basic"
                    label="Password"
                    variant="outlined"
                    type="password"
                    {...register("password")}
                  />
                </div>

                <div className="text-center mt-1">
                  <Button className="submit-btn" type="submit">
                    Login
                  </Button>
                </div>

                <div className="text">
                  <p>
                    Don't have an account?{" "}
                    <span>
                      <Link to={"/lawyer/signup"}>Sign Up</Link>
                    </span>
                  </p>
                </div>
              </form>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default LawyerSignin;
